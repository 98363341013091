html {
  min-height: 100vh;
  overflow: hidden;
}

.body{
  position: relative;
  height: 100vh;
  width: 100vw;
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: -1;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;


}
.App {

  font-family: Roboto, Arial, sans-serif;

  display: flex;
  align-items: baseline;
  align-content: center;
  justify-content: center;
  padding: 1em;
  border: 1px black solid;
  background: #494949;
  color: #fafafa;
  a {
    color: #fafafa;
    text-decoration: none;
  }

  .content {
    padding: 1em;
    h1{
      margin-top: 0;
    }

    .links{
      display: flex;
      flex-direction: row;

      a{
        display: block;
        height: 114px;
        width: 114px;
        text-align: center;
        justify-content: center;
        margin: 5px;

        background: #ffffff;
        color: black !important;

        img{
          height: 64px;
          width: 64px;
          margin: 8px auto;
        }
      }
    }
  }

  .photo {
    display: flex;
    align-items: flex-start;
    align-content: center;
    img{
      margin-top: -10em;
      margin-right: -4em;
      max-height: 6em;
      max-width: 6em;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
  }

  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #494949;
    color: #faf6e9;
    > div {
      margin: 1em;
    }
  }
}

@media (max-width: 650px) {
  .background {
  }
  .container{
  }
  .App{
    width: 70vw;
    @media (max-width: 400px) {
      width: 90vw;
    }
    flex-direction: column-reverse;
    text-align: center;

    .content{
      .links{
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    .photo{
      width: 100%;
      align-items: center;
      justify-content: center;
      img{
        margin: 1em auto;
        display: block;
      }
    }
  }
}
